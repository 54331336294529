<template>
    <v-row justify="center">
        <v-dialog
            v-model="addRoh"
            width="1100px"
            persistent
            >
            <template v-slot:activator="{ on, attrs }">
                    <v-btn
                        color="primary"
                        text
                        v-bind="attrs"
                        v-on="on"
                        small
                        @click="loadUsers"
                        >
                        <v-icon>mdi-plus</v-icon>
                        Add SAS
                    </v-btn>
            </template>

            <v-card>
                <v-card-title class="my-5">
                   <h4>Add SAS</h4>
                </v-card-title>
                <v-card-text class="mt-5">

                    <v-data-table
                        :headers="headers"
                        :items="users"
                        :calculate-widths="true"
                        class="ph-nonex-datatable"
                        :search = "search"
                        >
                        <template v-slot:top>
                            <v-toolbar flat color="white">
                                <v-layout>
                                    <v-spacer></v-spacer>
                                    <v-flex md4 class="search">
                                        <v-text-field
                                            v-model="search"
                                            placeholder="Search"
                                            label="Search"
                                            single-line
                                            hide-details
                                        ></v-text-field> 
                                    </v-flex>
                                </v-layout>
                            </v-toolbar>
                        </template>
                        <template v-slot:[`item.action`]="{item}">
                            <div>
                                <v-btn
                                    text
                                    color="primary"
                                    @click="addUser(item)"
                                    >
                                    <v-icon>mdi-account-plus</v-icon>
                                </v-btn>
                            </div>
                        </template>
                    </v-data-table>

                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        outlined color="error"
                        text
                        @click="cancelDialog"
                        >
                        Cancel
                    </v-btn>
                </v-card-actions>
            </v-card>

        </v-dialog>
    </v-row>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: ["item"],
    data () {
        return {
            cluster_no: '',
            cluster_name: '',
            addRoh: false,
            search: '',
            users: [],
            headers: [
                {
                    text: 'User Name',
                    sortable: true,
                    value: 'name'
                },
                {
                    text: 'User Email',
                    sortable: true,
                    value: 'email'
                },
                {
                    text: 'Action',
                    sortable: false,
                    value: 'action',
                    align: 'center'
                }
            ]
        }
    },
    computed:{
        ...mapGetters({
            sasDetails: 'salesProspecting/sasDetails',
            existingUploadedBranches: 'salesProspecting/existingUploadedBranches',
            otherPrimaryDetails: 'salesProspecting/otherPrimaryDetails'
        })
    },
    methods: {
        cancelDialog(){
            this.addRoh = false
            this.users = []
        },
        async loadUsers(){
            const res = await this.$store.dispatch('salesProspecting/doGetlAllUsers')
            res.data.result.map(item => {
                this.users.push(item)
            })
            console.log(this.item)
        },
        async addUser (i){
            const payload = {
                id: this.item.cluster_id,
                finalPayload : {
                    sas_email: i.email,
                    roh_id: this.item.id
                }
            }
            console.log(payload)
            const res = await this.$store.dispatch('salesProspecting/doADdSas', {payload})
            console.log(res)
            if(res.status === 200) {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'SAS Successfully Added!','messagetype':'success'});
                this.addRoh = false
                const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
                this.$store.commit('salesProspecting/SET_CLUSTER_DETAILS', res.data.result)
            } else if (res.status === 206 || res.code === 400 || res.code === 422){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': res.error ,'messagetype':'error'});
            } else {
                this.$store.dispatch('app/setSnackBar',{'status': true,'message': 'Error Occured!','messagetype':'error'});
            }
        }
    },
}
</script>
