<template>
    <v-row class="mt-2 mx-5">
        <v-col cols=12>
            <v-btn
                outlined
                color="info"
                style="float:right;"
                small
                >
                <AddClusterView></AddClusterView>
            </v-btn>
        </v-col>
        <v-col cols=12>
            <v-expansion-panels>
                <v-expansion-panel v-for="(item, parentIndex) in clusterDetails" :key="parentIndex" class="mb-3">
                    <v-expansion-panel-header>
                       <div>
                            <v-row>
                                <v-col cols="11">
                                    <span>Cluster {{parentIndex + 1}} - {{item.cluster_head_name}} ({{item.cluster_name}})</span>
                                </v-col>
                                <v-col cols="1">
                                    <v-icon @click.stop="" @click="deleteCluster(item)" color="error" y>mdi-delete</v-icon>
                                </v-col>
                            </v-row>
                        </div>
                    </v-expansion-panel-header>
                    <v-expansion-panel-content>
                        <v-row>
                            <v-col cols="6">
                                Regional Operations Head
                            </v-col>
                            <v-col cols=6>
                                <v-btn
                                    outlined
                                    color="info"
                                    style="float:right;"
                                    small
                                    >
                                    <AddRohView :item="item.id"> </AddRohView>
                                </v-btn>
                            </v-col>

                            <v-row>
                                <v-expansion-panels accordion class="mx-5 my-5">
                                    <v-expansion-panel v-for="(item, parentIndex) in item.cluster_roh" :key="parentIndex" class="mb-3">
                                       <b> <v-expansion-panel-header class="mb-3">
                                            <!-- ROH {{parentIndex + 1}} - {{item.roh_name}} -->
                                            <div>
                                                <v-row>
                                                    <v-col cols="11">
                                                        <span>ROH {{parentIndex + 1}} - {{item.roh_name}}</span>
                                                    </v-col>
                                                    <v-col cols="1">
                                                        <v-icon @click.stop="" @click="deleteRoh(item)" color="error" y>mdi-delete</v-icon>
                                                    </v-col>
                                                </v-row>
                                            </div>
                                        </v-expansion-panel-header> </b>
                                            <v-expansion-panel-content>
                                                <v-row>
                                                    <v-col cols="6">
                                                        Sub-Agent Specialist
                                                    </v-col>
                                                    <v-col cols=6>
                                                        <v-btn
                                                            outlined
                                                            color="info"
                                                            style="float:right;"
                                                            small
                                                            >
                                                            <AddSasView :item="item"> </AddSasView>
                                                        </v-btn>
                                                    </v-col>


                                                    <v-col>
                                                        <v-data-table
                                                            :headers="headers"
                                                            :items="item.cluster_sas"
                                                            :calculate-widths="true"
                                                            class="ph-nonex-datatable"
                                                            :search = "search"
                                                            >
                                                            <template v-slot:top>
                                                                <v-toolbar flat color="white">
                                                                    <v-layout>
                                                                        <v-spacer></v-spacer>
                                                                        <v-flex md4 class="search">
                                                                            <v-text-field
                                                                                v-model="search"
                                                                                placeholder="Search"
                                                                                label="Search"
                                                                                single-line
                                                                                hide-details
                                                                            ></v-text-field> 
                                                                        </v-flex>
                                                                    </v-layout>
                                                                </v-toolbar>
                                                            </template>
                                                            <template v-slot:[`item.action`]="{item}">
                                                                <div>
                                                                    <v-btn
                                                                        text
                                                                        color="error"
                                                                        @click="deleteSas(item)"
                                                                        >
                                                                        <v-icon>mdi-trash-can-outline</v-icon>
                                                                    </v-btn>
                                                                </div>
                                                            </template>
                                                        </v-data-table>
                                                    </v-col>


                                                </v-row>
                                            </v-expansion-panel-content>
                                    </v-expansion-panel>
                                </v-expansion-panels>
                            </v-row>

                        </v-row>
                    </v-expansion-panel-content>
                </v-expansion-panel>
            </v-expansion-panels>
        </v-col>

        <v-dialog
                v-model="deleteClusterDialog"
                persistent
                max-width="400"
                >
                <v-card>
                    <v-card-title>
                        Delete Cluster
                    </v-card-title>
                    <v-card-text>
                        <br>
                        <p style="font-size: 15px;">Permanently delete cluster? You can't undo this.</p>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn
                            text
                            @click="cancelClusterDialog"
                            >
                            Cancel
                        </v-btn>
                        <v-btn
                            text
                            @click="confirmClusterDelete"
                            >
                            Confirm
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>

    </v-row>
</template>
<script>
import AddClusterView from '../components/modules/salesProspecting/AddClusterView.vue';
import AddRohView from '../components/modules/salesProspecting/AddRohView.vue';
import AddSasView from '../components/modules/salesProspecting/AddSasView.vue';
import { mapGetters } from 'vuex';
export default {
    data () {
        return {
            search: '',
            clusterList: [],
            deleteClusterDialog: false,
            cluster_id: '',
            headers: [
                {
                    text: 'SAS Name',
                    sortable: true,
                    value: 'sas_name'
                },
                {
                    text: 'SAS Email',
                    sortable: true,
                    value: 'sas_email'
                },
                {
                    text: 'Action',
                    sortable: false,
                    value: 'action',
                    align: 'center'
                }
            ]
        }
    },
    components:{
        AddClusterView: AddClusterView,
        AddRohView: AddRohView,
        AddSasView: AddSasView,
    },
    async mounted () {
        const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
        this.$store.commit('salesProspecting/SET_CLUSTER_DETAILS', res.data.result)
    },
    computed: {
		...mapGetters({
			clusterDetails: 'salesProspecting/clusterDetails',
		})
	},
    methods: {
        async deleteSas(i){
            console.log(i)
            const payload = {
                id : i.cluster_id,
                finalPayload : {
                    roh_id : i.roh_id,
                    sas_id : i.id
                }
            }
            console.log(payload)

            const res = await this.$store.dispatch('salesProspecting/doDeleteSas', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'SAS Deleted Successfully','messagetype':'success'});
                const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
                this.$store.commit('salesProspecting/SET_CLUSTER_DETAILS', res.data.result)
            }
        },
        async deleteRoh(i){
            const payload = {
                id : i.cluster_id,
                finalPayload : {
                    roh_id : i.id
                }
            }
          const res = await this.$store.dispatch('salesProspecting/doDeleteRoh', {payload})
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'ROH Deleted Successfully','messagetype':'success'});
                const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
                this.$store.commit('salesProspecting/SET_CLUSTER_DETAILS', res.data.result)
            }
        },
        deleteCluster(i){
            console.log(i)
            this.deleteClusterDialog = true
            this.cluster_id = i.id
        },
        cancelClusterDialog(){
            this.deleteClusterDialog = false   
            this.cluster_id = ''
        },
        async confirmClusterDelete(){
            const res = await this.$store.dispatch('salesProspecting/doDeleteCluster', this.cluster_id)
            if (res.status == 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Cluster Deleted Successfully','messagetype':'success'});
                const res = await this.$store.dispatch('salesProspecting/doGetAllCluster')
                this.$store.commit('salesProspecting/SET_CLUSTER_DETAILS', res.data.result)
                this.deleteClusterDialog = false   
                this.cluster_id = ''
            }
        }
    }
}
</script>
